export class FetcherUnknownError extends Error {
  public readonly cause: unknown;

  constructor(cause: unknown) {
    const errorMessage = `An unexpected error occurred`;
    super(errorMessage);

    this.name = "FetcherUnknownError";
    this.message = errorMessage;
    this.cause = cause;
  }
}
