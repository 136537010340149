import { IFetcherErrorContext } from "./http-error-serializer";

export class FetcherHTTPError extends Error {
  public readonly context: IFetcherErrorContext;
  public readonly status: number;

  constructor(context: IFetcherErrorContext) {
    const errorMessage = `Request failed with status code ${context.response.status}`;
    super(errorMessage);

    this.name = "FetcherHTTPError";
    this.message = errorMessage;
    this.context = context;
    this.status = context.response.status;
  }
}
