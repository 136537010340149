import { getRequest } from "./get-request";
import { postRequest } from "./post-request";
import { putRequest } from "./put-request";
import { deleteRequest } from "./delete-request";

export const fetcher = {
  get: getRequest,
  post: postRequest,
  put: putRequest,
  delete: deleteRequest,
};

export type { IFetcherErrorContext } from "./http-error-serializer";

export { FetcherHTTPError } from "./fetcher-http.error";
export { FetcherConnectionLostError } from "./fetcher-connection-lost.error";
export { FetcherFormValidationError } from "./fetcher-form-validation.error";
export { FetcherBusinessRuleError } from "./fetcher-business-rule.error";
