import {
  FetcherConnectionLostError,
  FetcherFormValidationError,
  FetcherBusinessRuleError,
} from "@/core/fetcher";
import { report } from "@chatfood/bug-reporter";

export function reportAndReturnUnexpectedError<T extends Error, U>(
  errorConstructor: new (
    repoName: string,
    message: string,
    errorCode: U,
    errorCause: unknown
  ) => T,
  errorCause: any,
  repoName: string,
  errorCode: U
): T {
  if (
    !(errorCause instanceof FetcherConnectionLostError) &&
    !(errorCause instanceof FetcherFormValidationError) &&
    !(errorCause instanceof FetcherBusinessRuleError)
  ) {
    const responseStatusCode: number =
      "status" in errorCause ? errorCause.status : 1000;

    report(errorCause, {
      tags: {
        "http.response_code": responseStatusCode,
      },
      context: {
        "Serialized error": JSON.stringify(errorCause),
      },
    });
  }

  throw new errorConstructor(
    repoName,
    "An unexpected error happened",
    errorCode,
    errorCause
  );
}
