import { getClient } from "./client";
import { errorHandler } from "./error-handler";

export async function deleteRequest(url: string): Promise<void> {
  try {
    await getClient().delete(url);
  } catch (err: unknown) {
    throw await errorHandler(err);
  }
}
