import { HTTPError } from "ky";
import { FetcherConnectionLostError } from "./fetcher-connection-lost.error";
import { FetcherHTTPError } from "./fetcher-http.error";
import { httpErrorSerializer } from "./http-error-serializer";
import { FetcherUnknownError } from "./fetcher-unknown.error";
import { FetcherFormValidationError } from "./fetcher-form-validation.error";
import { FetcherBusinessRuleError } from "./fetcher-business-rule.error";

export async function errorHandler(err: unknown): Promise<Error> {
  if (err instanceof TypeError && err.message.includes("Failed to fetch")) {
    return new FetcherConnectionLostError();
  }

  if (!(err instanceof HTTPError)) {
    return new FetcherUnknownError(err);
  }

  const responseStatusCode = err.response.status;
  const errorContext = await httpErrorSerializer(err);
  let errorBody;

  try {
    errorBody = JSON.parse(errorContext.response.body);
  } catch {
    errorBody = undefined;
  }

  const bodyErrors: Record<string, any> | undefined = errorBody?.errors;
  if (responseStatusCode === 422 && bodyErrors) {
    return new FetcherFormValidationError(bodyErrors, errorContext);
  }

  const bodyError = errorBody?.error;
  if (responseStatusCode === 422 && bodyError?.code) {
    return new FetcherBusinessRuleError(
      bodyError.code,
      bodyError.message ?? "Request failed due to a business rule",
      errorContext,
      bodyError.context ?? undefined
    );
  }

  return new FetcherHTTPError(errorContext);
}
