import QuickLRU from "quick-lru";

const FIVE_MINUTES = 1000 * 60 * 5;

const cacheStorage = new QuickLRU<string, Response>({
  maxSize: 500,
  maxAge: FIVE_MINUTES,
});

export { cacheStorage };
