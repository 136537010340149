import type { Options } from "ky";
import { getClient } from "./client";
import { errorHandler } from "./error-handler";

type IQueryString = Options["searchParams"];

export async function getRequest<T, U extends IQueryString = undefined>(
  url: string,
  config?: {
    queryString?: U;
    cacheTTL?: number;
  }
): Promise<T> {
  try {
    const options: Options = {};

    if (config?.queryString) {
      options.searchParams = config.queryString;
    }

    const response = await getClient(config?.cacheTTL).get(url, options);
    return await response.json<T>();
  } catch (err: unknown) {
    throw await errorHandler(err);
  }
}
