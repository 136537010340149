import ky from "ky";
import { globalConfig } from "@/v2/core";
import { cacheStorage } from "./cache-storage";
import { report } from "@chatfood/bug-reporter";

const clientNetworkOnly = ky.extend({
  prefixUrl: globalConfig.apiBaseUrl,
  retry: 0,
  headers: {
    accept: "application/json",
    "content-type": "application/json",
  },
  hooks: {
    beforeRequest: [
      (request: Request): void => {
        let accessToken: string | undefined;

        try {
          const storageData = window.localStorage.getItem("oauth");
          accessToken = storageData
            ? JSON.parse(storageData)?.access_token
            : undefined;
        } catch (e: unknown) {
          report(e);
          accessToken = undefined;
        }

        if (accessToken) {
          request.headers.set("Authorization", `Bearer ${accessToken}`);
        }
      },
    ],
  },
});

const clientWithCache = ({ cacheTTL = 0 }): typeof ky => {
  return clientNetworkOnly.extend({
    hooks: {
      beforeRequest: [
        (request): Response | void => {
          if (request.method === "GET") {
            const cached = cacheStorage.get(request.url)?.clone();

            if (cached && !cached.bodyUsed) {
              return cached;
            }
          }
        },
      ],
      afterResponse: [
        (_input, _options, response): Response => {
          if (
            _input.method !== "GET" ||
            (response.status >= 400 && response.status < 600)
          ) {
            return response;
          }

          const cached = cacheStorage.get(response.url);
          if (!cached) {
            cacheStorage.set(response.url, response.clone(), {
              maxAge: cacheTTL,
            });
          }

          return response;
        },
      ],
    },
  });
};

export const getClient = (cacheTTL?: number): typeof ky => {
  if (cacheTTL && cacheTTL > 0) {
    return clientWithCache({ cacheTTL });
  }

  return clientNetworkOnly;
};
