import type { HTTPError } from "ky";

export type IFetcherErrorContext = Awaited<
  ReturnType<typeof httpErrorSerializer>
>;

/**
 * Reports a repo error to our error reporting tool
 * while preserving and providing as much context
 * as possible about the failure
 *
 * @param err HTTPError or Sub class of HTTPError
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export async function httpErrorSerializer(err: HTTPError) {
  return {
    response: {
      bodyUsed: err.response.bodyUsed,
      body: await err.response.text(),
      ok: err.response.ok,
      redirected: err.response.redirected,
      status: err.response.status,
      type: err.response.type,
      url: err.response.url,
    },
    request: {
      bodyUsed: err.request.bodyUsed,
      cache: err.request.cache,
      method: err.request.mode,
      mode: err.request.mode,
      redirect: err.request.redirect,
      referrer: err.request.referrer,
      referrerPolicy: err.request.referrerPolicy,
      url: err.request.url,
    },
  };
}
